// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdsCard_fauUB{border-radius:2rem;display:flex;flex-direction:column;justify-content:space-between;overflow:hidden;position:relative;width:100%}.AdsCard_fauUB .button_OQiVU,.AdsCard_fauUB .tag_Xf-mc{position:absolute;z-index:1}.AdsCard_fauUB._btnRight_ueahl .button_OQiVU{bottom:2.4rem;left:unset;right:2.4rem}.AdsCard_fauUB._btnRight_ueahl .tag_Xf-mc{left:2rem;right:unset;top:2rem}.AdsCard_fauUB._btnFull_4xMjB .button_OQiVU{bottom:2.4rem;left:2.4rem;right:2.4rem;width:unset}.AdsCard_fauUB._small_hA-pF .button_OQiVU{bottom:2rem;left:2rem}.AdsCard_fauUB._small_hA-pF .tag_Xf-mc{font-size:1rem;font-weight:500;letter-spacing:-.04em;line-height:1.2rem;padding:.6rem}.AdsCard_fauUB._small_hA-pF._btnRight_ueahl .button_OQiVU{bottom:2rem;left:unset;right:2rem}.AdsCard_fauUB._small_hA-pF._btnFull_4xMjB .button_OQiVU{bottom:2rem;left:2rem;right:2rem;width:unset}.image_pw9fx{height:100%;object-fit:contain;pointer-events:none;width:100%}.tag_Xf-mc{align-items:center;background:#17181c66;color:#fff;display:flex;font-size:1.2rem;gap:.4rem;letter-spacing:-.048rem;line-height:1.44rem;padding:.6rem .8rem;right:2rem;top:2rem}.button_OQiVU,.tag_Xf-mc{border-radius:.8rem;font-weight:500;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;z-index:1}.button_OQiVU{background:#fff;bottom:2.4rem;color:#17181c;font-size:1.4rem;left:2.4rem;letter-spacing:-.056rem;line-height:1.68rem;padding:.8rem 1.6rem;transition:opacity .3s ease}@media(max-width:767.98px){.button_OQiVU{display:none}}.button_OQiVU:hover{opacity:.9}.button_OQiVU svg{height:1.6rem;width:1.6rem}.link_R5i0L{bottom:0;left:0;position:absolute;right:0;top:0;z-index:2}.link_R5i0L:hover+.button_OQiVU{opacity:.9}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"AdsCard": "AdsCard_fauUB",
	"button": "button_OQiVU",
	"tag": "tag_Xf-mc",
	"_btnRight": "_btnRight_ueahl",
	"_btnFull": "_btnFull_4xMjB",
	"_small": "_small_hA-pF",
	"image": "image_pw9fx",
	"link": "link_R5i0L"
};
module.exports = ___CSS_LOADER_EXPORT___;
